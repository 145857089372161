<template>
  <BaseDialog
    :max-width="700"
    :propDialog="dialog"
    title="order.add-request-title"
    :validity="!!orderTotal && itemListValid"
    :loader="loading"
    botIcon="box"
    @confirm="submitRequest"
    @closeDialog="setDialog"
    @selectBotIcon="addToBasket(extras.foodContainer)"
  >
    <div class="sticky-el--title text-h6 primary--text">
      {{ order.tableName }} - {{ order.customerName }}
    </div>
    <NewRequestItemList :valid.sync="itemListValid" />
    <DishList container="order-dialog" :fullScreen="false" />
  </BaseDialog>
</template>

<script>
import NewRequestItemList from './NewRequest/NewRequestItemList'
import DishList from '@/components/dishes/DishList'
import KitchenRequestService from '@/services/modules/KitchenRequestService'
import {
  createInvoice,
  displaySuccessMessage,
  displayErrorMessage
} from '@/utils'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'OrderAddRequest',

  components: {
    NewRequestItemList,
    DishList
  },

  props: {
    order: Object,
    dialog: Boolean,
    type: {
      validator: function(value) {
        return ['order', 'invoice'].includes(value)
      }
    }
  },

  data: () => ({
    loading: false,
    itemListValid: true
  }),

  computed: {
    ...mapState('order', ['basket']),
    ...mapState('common', ['extras']),
    ...mapGetters('order', ['orderTotal'])
  },

  methods: {
    ...mapActions('order', ['addToBasket']),

    setDialog(val) {
      this.$emit('update:dialog', val)
    },

    async submitRequest() {
      this.loading = true
      const id = this.type === 'order' ? this.order._id : this.order.orderId
      try {
        await KitchenRequestService.createKitchenRequests(id, this.basket)
        displaySuccessMessage('order.message-submit-order')
        this.$store.commit('order/CLEAR_BASKET')
        this.$mitt.emit('setDialog', false)
        this.$mitt.emit('updateHasWaiting', {
          orderId: this.order._id,
          hasWaiting: true
        })
        this.$socket.client.emit('update-request')
        if (this.type === 'invoice') {
          await createInvoice(this.order.orderId)
        }
        this.setDialog(false)
      } catch (err) {
        displayErrorMessage(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped></style>
